import axios from 'axios'

axios.interceptors.request.use(config=>{
    // config.url = encodeURI(config.url)
    return config
})

axios.interceptors.response.use(response =>{
    // console.log(response)
    if(response.headers['content-disposition']){
        return Promise.resolve(response)
    }
    // mock(response.data)
    return Promise.resolve(response.data)
}, (error) => {
    // console.log(error.response)
    if(error.response.status === 401){
        window.location = `//${window.location.host}/login`
    }
    return Promise.resolve(error.response.data)
})


// const baseUrl = '//81.69.1.166:8130'
const protocol = window.location.protocol
const baseUrl = protocol ==='https:' ? '//4s-api.maruzon.cn' : '//81.69.1.166:8130'
const baseUrl2 = '//81.69.1.166:8131'

const http = {
    baseUrl,
    q2:function(){
        const newReq = {...this}
        newReq.baseUrl = baseUrl2
        return newReq
    },
    request: function(method, path, data){
        const token = localStorage.getItem('token')
        const config = {
            method,
            headers: {
                Authorization: 'Bearer ' + token,
            },
            baseURL: this.baseUrl,
            url: path
        }
        if(method === 'get'){
            config.params = data
        }else{
            config.data = data
        }

        return axios(config)
    },
    get(path, data){
        return this.request('get', path, data)
    },
    post(path, data){
        return this.request('post', path, data)
    },
    put(path, data){
        return this.request('put', path, data)
    },
    delete(path, data){
        return this.request('delete', path, data)
    },
    download(path, datas){
        const token = localStorage.getItem('token')
        const config = {
            method: 'get',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            baseURL: baseUrl,
            url: path,
            responseType: 'arraybuffer'
        }
        config.params = datas

        return axios(config)
    }
}


export default http