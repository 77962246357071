import React, { useEffect, useRef, useState } from "react";
import http from "./http";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  BarSeriesOption,
  LineChart,
  BarChart,
  PieChart,
  LineSeriesOption,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  TitleComponentOption,
  GridComponent,
  LegendComponent,
  GridComponentOption,
  ToolboxComponent,
  TooltipComponent,
  ToolboxComponentOption,
  TooltipComponentOption,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { getFuncsIncome } from './api'
// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);
export const option = {
  // title: {
  //   text: "",
  // },
  // tooltip: {
  //   trigger: "item",
  //   position: function (point, params, dom, rect, size) {
  //     // 固定在顶部
  //     return ["20%", "10%"];
  //   },
  // },
  grid: {
    left: "0",
    top: "0",
    right: "0",
    bottom: "0",
  },
  //   legend: {
  //     top: "5%",
  //     left: "center",
  //   },
  series: [
    {
      //   name: "Access From",
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      itemStyle: {
        // borderRadius: 10,
        borderColor: "#fff",
        borderWidth: 2,
      },
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: true,
          fontSize: "1.5rem",
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
};
function Income(props) {
  const ref = useRef(null);
  // const subjectIds = ["101010", "101020", "101030", "101040"];
  const colors = ["#42E0FC", "#FF8F00", "#9013FE", "#B8E986", '#FEC613', '#E98686'];
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const getData = async () => {
    let datas = [];
    const resp = await getFuncsIncome(props.subjectIds, props.year, props.month)
    setData(resp.subjectReportData01s);
    setChart(resp.subjectReportData01s);
  };

  useEffect(()=>{
    getData()
  }, [])

  const setChart = (datas) => {
    if (!ref.current || !ref.current) return;
    const chart = echarts.init(ref.current);
    const dts = [];
    let total = 0;
    // option.title.text = params.title
    datas.forEach((element) => {
      dts.push({
        name: element.subject_name,
        value: element.subject_value,
      });
      total += element.subject_value;
    });
    // console.log("====");
    // option.title.text = props.title
    option.color = colors;
    option.series[0].data = dts;
    chart.setOption(option);
    setTotal(total);
  };

  

  return (
    <div className="income-ratio">
      <div className="title">{props.name}</div>
      <div
        className=""
        ref={ref}
        style={{ width: "100%", height: '15rem', margin: "" }}
      ></div>
      {data.map((val, index) => {
        let sum = 0
        data.forEach(vl=>{
          sum += vl.subject_value
        })
        const ratio = (
          (val.subject_value /
            sum) *
          100
        ).toFixed(2);
        return (
          <div className="ratio-container" key={index}>
            <div className="ratio-item">
              <div className="ratio-item-title">
                <span className="name">
                  {val.subject_name}
                </span>
                <span className="number">
                  {val.subject_value}({ratio}%)
                </span>
              </div>
              <div className="ratio-item-progress">
                <div
                  className="ratio-item-progress-on"
                  style={{ width: ratio + "%", background: colors[index] }}
                ></div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Income;
