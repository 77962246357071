import React, { useEffect, useState, useRef } from "react";
import { Button, Calendar } from 'antd-mobile'
import { handelResp,handleMonth } from "./api";
import http from "./http";
import "./report.less";
import dayjs from 'dayjs'

const RateColor = 'red'
const MonthColor = '#333333'
const TodayColor = '#057fbb'
function Report(props) {
  const mapping = {
    交付完成率: {name: "delivery_complete_rate", color: RateColor},
    零售交付完成率: {name: "retail_complete_rate", color: RateColor},

    月累计订单: {name:"monthly_order", color:MonthColor,},
    月累计零售订单: {name:"monthly_retail_order", color:MonthColor,},

    当日展厅新增订单: {name:"daily_4s_order", color:TodayColor,},
    当日DCC新增订单: {name:"daily_dcc_order", color:TodayColor,},
    当日二网新增订单: {name:"daily_2nd_order", color:TodayColor,},

    月预算交付目标: {name:"monthly_delivery_target", color:MonthColor,},
    月实际交付完成: {name:"monthly_delivery_achievement", color:MonthColor,},
    月预算零售交付目标: {name:"monthly_retail_target", color:MonthColor,},
    月累计零售交付完成: {name:"monthly_retail_achievement", color:MonthColor,},

    当日展厅交付: {name:"daily_4s_delivery", color:TodayColor,},
    当日DCC交付: {name:"daily_dcc_delivery", color:TodayColor,},
    当日二网交付: {name:"daily_2nd_delivery", color:TodayColor,},

    月累计批售车交付: {name:"monthly_wholesale_delivery", color:MonthColor,},
    月累计9字头交付: {name:"monthly_9_delivery", color:MonthColor,},

    当日批售车交付: {name:"daily_wholesale_delivery", color:TodayColor,},
    当日9字头交付: {name:"daily_9_delivery", color:TodayColor,},

    月累计展厅客流: {name:"monthly_passenger", color:MonthColor,},
    月累计DCC线索: {name:"monthly_dcc", color:MonthColor,},

    当日展厅客流: {name:"daily_passenger", color:TodayColor,},
    当日DCC线索: {name:"daily_dcc", color:TodayColor,},
    
  };
  const [data, setData] = useState([]);
  const [top, setTop] = useState(0)
  const [date, setDate] = useState(new Date())
  const [showDatePicker, setShowDatePicker] = useState(false)
  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const getData = async () => {
    const resp = await http.get("/daily/selectJyDaily", {
      parent_id: localStorage.getItem("coId"),
      year: date.getFullYear().toString(),
      month: handleMonth(date.getMonth()+1),
      day: handleMonth(date.getDate()),
    });
    handelResp(resp).then((data) => {
      // console.log(data);
      if (data.jyDailys) {
        setData(data.jyDailys);
      } else {
        setData([]);
      }
    });
  };
  useEffect(() => {
    getData();
    // if(ref1.current){
      // console.log(ref1.current)
      // ref1.current.onscroll = e=>{
        // ref2.current.style.top = e.target.scrollTop + 'px'
        // ref3.current.style.top = e.target.scrollTop + 'px'
      // }
    // }
  }, [date]);
  const subjects = Object.keys(mapping);
  const onScroll = e=>{
    // console.log(e.target.scrollTop)
    setTop(e.target.scrollTop)
  }
  const today = date.getDate()
  const total = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate()
  // console.log(today /total)
  return (
    <div className="report" style={{display: props.showMessage ? "" : 'none'}}>
      <div className="head">
        <span>选择时间:</span>&nbsp;&nbsp;<Button size="small" onClick={()=>{
          setShowDatePicker(!showDatePicker)
        }}>{date.getFullYear()}-{handleMonth(date.getMonth()+1)}-{handleMonth(date.getDate())}</Button>
        
        <div style={{marginTop: '0.2rem'}}>
          <span style={{color: 'red'}}>进度时间:&nbsp;&nbsp;{(today /total * 100 ).toFixed(0)}%</span>
          <span></span>
        </div>
        {showDatePicker && <div className="date-container">
        <Calendar
          renderLabel={date => {
            if (dayjs(date).isSame(dayjs(), 'day')) return '今天'
            if (date.getDay() === 0 || date.getDay() === 6) {
              return '周末'
            }
          }}
          selectionMode='single'
          value={date}
          onChange={val => {
            setDate(val)
            setShowDatePicker(false)
          }}
        />
        </div>}
      </div>
      
      <div className="table" onScroll={onScroll} >
        <div className="subject">
          <div className="title" style={{top}}>
            <span className="txt">
              指标项目
            </span>
          </div>
          <div className="subject-items">
            {subjects.map((val, i) => {
              const color = mapping[val]['color']
              return <div className="subject-item" key={i} style={{color}}>
                {val}
              </div>;
            })}
          </div>
        </div>
        <div className="content">
            <div className="comp" style={{backgroundColor: '#FFE7E7'}}>
                  <div className="title" style={{top}}>
                    <div className="txt">合计</div>
                  </div>
                  <div className="items">
                    {subjects.map((sub, key)=>{
                      const isFlot = sub.indexOf('率')> -1
                      let value = 0
                      data.forEach(val=>{
                        value += val[mapping[sub]['name']]
                      })
                      if(sub === '交付完成率'){
                        let a = 0
                        let b = 0
                        data.forEach(val=>{
                          a += val[mapping['月预算交付目标']['name']]
                        })
                        data.forEach(val=>{
                          b += val[mapping['月实际交付完成']['name']]
                        })
                        value = a ? b / a: 0
                      }
                      if(sub === '零售交付完成率'){
                        let a = 0
                        let b = 0
                        data.forEach(val=>{
                          a += val[mapping['月预算零售交付目标']['name']]
                        })
                        data.forEach(val=>{
                          b += val[mapping['月累计零售交付完成']['name']]
                        })
                        value = a ? b / a: 0
                      }
                      const color = mapping[sub]['color']
                      return <div key={key} className="value" style={{color}}>
                        {isFlot ? (value*100).toFixed(0) : value}{ isFlot && '%'}
                      </div>
                    })}
                  </div>
            </div>
          {data.map((val, i)=>{
            return <div className="comp" key={i}>
                <div className="title" style={{top}}>
                  <div className="txt"> {val.co_name.slice(0, 5)}</div>
                </div>
                <div className="items">
                  {subjects.map((sub, key)=>{
                    const isFlot = sub.indexOf('率')> -1
                    const value = val[mapping[sub]['name']]
                    const color = mapping[sub]['color']
                    return <div key={key} className="value" style={{color}}>
                      {isFlot ? (value*100).toFixed(0) : value}{ isFlot && '%'}
                    </div>
                  })}
                </div>
            </div>
          })}
        </div>
      </div>
    </div>
  );
}

export default Report;
