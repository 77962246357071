import http from './http';
import { Toast } from 'antd-mobile'
export const handelResp = (resp, successMsg, errorMsg)=>{
    return new Promise((resolve, reject)=>{
      if(resp.code === 200){
        if(successMsg){
        //   message.success(successMsg)
            Toast.show({icon: 'success',content: successMsg})
        }
        resolve(resp.data)
      }else{
        if(errorMsg){
        //   message.error(errorMsg)
            Toast.show({icon: 'fail', content: errorMsg})
        }else{
          if(typeof(resp.msg)==='string'){
            // message.error(resp.msg)
            Toast.show({icon: 'fail', content: resp.msg})
          }else{
            // message.error(resp.msg.Message)
            Toast.show({icon: 'fail', content: resp.msg.Messag})
          }
        }
        reject()
      }
    })
  }

function handleYear(year){
    if(typeof(year)==='number'){
        year = year.toString()
    }
    return year
}

export function handleMonth(month){
    if(typeof(month)==='number'){
        month =  month < 10 ? '0'+month.toString(): month.toString()
    }
    return month
}

//getCurrentBudgetInfo
export async function getCurrentBudgetInfo(){
    const resp = await http.get('/base/getCurrentBudgetInfo')
    return handelResp(resp)
}

//集团实际净利润
export async function total_profit(year, month){
    const data = {
        "co_id": localStorage.getItem('coId'),
        "ea_type": "A",
        "month": handleMonth(month),
        "subject_ids": [
            "704030"
        ],
        "year": handleYear(year)
    }
    const resp = await http.put('/indi/getIndicatorMonthlyYTM01', data)
    return handelResp(resp)
}


//各净利润公司排名
export async function getCompTop(year, month){
    const data = {
        "co_id": localStorage.getItem('coId'),
        "ea_type": "A",
        "month": handleMonth(month),
        "subject_ids": [
            "704030"
        ],
        "year": handleYear(year)
    }
    const resp = await http.put('/indi/getIndicatorMonthlyYTMCorp01', data)
    return handelResp(resp)
}

//职能部门费用构成
export async function getFuncsIncome(subject_ids, year, month){
    const data = {
        "co_id": localStorage.getItem('coId'),
        "ea_type": "A",
        "month": handleMonth(month),
        "subject_ids": subject_ids,
        "year": handleYear(year)
    }
    const resp = await http.put('/indi/getIndicatorMonthlyYTM01', data)
    return handelResp(resp)
}
