import React, { useState, useRef, useEffect } from "react";
import Logo from "./assets/logo.png";
import "./dashboard.less";
import { DoubleLeftOutlined, DoubleRightOutlined, MessageOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { total_profit, getCompTop, getFuncsIncome } from "./api";
import _ from "lodash";
import Tops from "./Tops";
import Img1 from "./assets/编组 (1).png";
import Img2 from "./assets/编组 (2).png";
import Img3 from "./assets/编组 (3).png";
import Img4 from "./assets/编组 (4).png";
import Img5 from "./assets/编组 (5).png";
import Img6 from "./assets/编组 (6).png";
import Income from "./income";
import Message from './message';
import Report from './report';

const delay = _.debounce((fn) => {
  fn();
}, 100);
const parseCurrency = (val) => {
  if (!val) return "";
  const str = val.toLocaleString("zh", { style: "currency", currency: "CNY" });
  return str.replace("¥", "");
};

function Dashboard() {
  const ref = useRef();
  const ref2 = useRef();
  const now = new Date();
  const [year, setYear] = useState(parseInt(localStorage.getItem("year")));
  const [month, setMonth] = useState(parseInt(localStorage.getItem("month")));
  const [totalProfit, setTotalProfit] = useState(0);
  const [topProfit, setTopProfit] = useState([]);
  const [datas, setDatas] = useState([]);
  const [ showMessage, setShowMessage ] = useState(false)
  const [ showReport, setShowReport ] = useState(false)
  const getIndex = (index) => {
    const dt = {};
    // console.log(datas)
    if (index < datas.length) {
      dt.name = datas[index].subject_name;
      dt.value = datas[index].subject_value;
    }
    return dt;
  };
  const getData = async () => {
    const profit = await total_profit(year, month);
    // console.log(profit)
    setTotalProfit(profit.subjectReportData01s[0].subject_value);
    const top_profit = await getCompTop(year, month);
    setTopProfit(
      top_profit.subjectEAReportData01s
        .map((val) => {
          return {
            name: val.co_name,
            value: val.subject_value,
          };
        })
        // .sort((a, b) => a.value - b.value)
    );
    const resp = await getFuncsIncome(
      [
        "B010",
        "B040",
        "701038",
        "701020",
        "701021",
        "701022",
        "701023",
        "B030",
        "702011",
        "702010",
      ],
      year,
      month
    );
    // console.log(resp)
    setDatas(resp.subjectReportData01s);
  };
  useEffect(() => {
    delay(getData);
  }, [month, year]);

  useEffect(() => {
    getData();
    if (ref.current) {
      const t = ref.current;
      const width = (t.scrollWidth - t.clientWidth * 0.5 * 2) / 12;
      t.scrollLeft = width * month;
    }
  }, []);

  const optvs = [
    {
      name: "一季度",
      value: "1Q",
    },
    {
      name: "二季度",
      value: "2Q",
    },
    {
      name: "三季度",
      value: "3Q",
    },
    {
      name: "四季度",
      value: "4Q",
    },
    {
      name: "上半年",
      value: "1H",
    },
    {
      name: "下半年",
      value: "2H",
    },
    {
      name: "整年度",
      value: "Y",
    },
  ];

  return (
    <div className="dashboard">
      <div className="top-header">
        <div className="header">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="btn-group">
            <div className="msg-btn" onClick={()=>{
              setShowMessage(!showMessage)
              setShowReport(false)
            }}>
              <MessageOutlined />
              {/* &nbsp; */}
              {/* {showMessage ? '退出' : '打开'}数据助手 */}
            </div>
            <div className="msg-btn" onClick={()=>{
              setShowReport(!showReport)
              setShowMessage(false)
            }}>
            <UnorderedListOutlined />
              {/* &nbsp; */}
              {/* {showMessage ? '退出' : '打开'}日报 */}
            </div>
          </div>
        </div>
        <div className="date-container">
          <div className="year-container">
            <div className="icon" onClick={() => setYear(year - 1)}>
              <DoubleLeftOutlined />
            </div>
            <div className="year">{year}</div>
            <div
              className="icon"
              style={{ color: year < now.getFullYear() ? "" : "#D8D8D8" }}
              onClick={() => {
                if (year < now.getFullYear()) {
                  setYear(year + 1);
                }
              }}
            >
              <DoubleRightOutlined />
            </div>
          </div>
          <div
            className="opts"
            ref={ref}
            onScroll={(e) => {
              const t = e.target;
              const width = (t.scrollWidth - t.clientWidth * 0.5 * 2) / 12;
              if (t.scrollLeft && t.scrollLeft % width > width / 9) {
                const count = Math.floor(t.scrollLeft / width) + 1;
                // console.log(count);
                if (count < 13) {
                  setMonth(count);
                }
              }
              // console.log(e.target.scrollLeft)
              // console.log(Math.floor(e.target.scrollLeft/width) + 1)
              // console.log(e, e.target.scrollLeft)
            }}
          >
            {Array(12)
              .fill(0)
              .map((_, index) => {
                return (
                  <span
                    key={index}
                    style={{
                      marginLeft: index === 0 ? "4rem" : "",
                      marginRight: index === 11 ? "8rem" : "",
                    }}
                    className={index + 1 === month ? "opt active" : "opt"}
                    onClick={(e) => {
                      if (ref.current) {
                        const t = ref.current;
                        const width =
                          (t.scrollWidth - t.clientWidth * 0.5 * 2) / 12;
                        t.scrollLeft = width * (index + 1);
                        setMonth(index + 1);
                      }
                    }}
                  >
                    {index + 1}
                  </span>
                );
              })}
          </div>
        </div>

        <div
          className="opts-v"
          ref={ref2}
          onScroll={(e) => {
            const t = e.target;
            const width =
              (t.scrollWidth - t.clientWidth * 0.5 * 2) / optvs.length;
            if (t.scrollLeft && t.scrollLeft % width > width / 2) {
              const count = Math.floor(t.scrollLeft / width);
              // console.log(count);
              if (count < optvs.length + 1) {
                setMonth(optvs[count].value);
              }
            }
            // console.log(e.target.scrollLeft)
            // console.log(Math.floor(e.target.scrollLeft/width) + 1)
            // console.log(e, e.target.scrollLeft)
          }}
        >
          {optvs.map((val, index) => {
            return (
              <span
                style={{
                  marginLeft: index === 0 ? "12rem" : "",
                  marginRight: index === 11 ? "12rem" : "",
                }}
                className={val.value === month ? "opt active" : "opt"}
                key={index}
                onClick={(e) => {
                  if (ref2.current) {
                    const t = ref2.current;
                    const width =
                      (t.scrollWidth - t.clientWidth * 0.5 * 2) / optvs.length;
                    t.scrollLeft = width * (index + 1);
                    setMonth(val.value);
                  }
                }}
              >
                {val.name}
              </span>
            );
          })}
        </div>
      </div>
      <div className="subject_name">集团实际净利润</div>
      <div className="subject_value">{parseCurrency(totalProfit)}</div>
      <div className="subject_name" style={{ marginTop: "1.46rem" }}>
        各4s店销售净利
      </div>
      <Tops data={topProfit} isW={true} />
      <div className="line"></div>
      <div className="subject_name">销售</div>
      <div className="card-container">
        <div className="min-card">
          <div className="min-image">
            <img src={Img1} alt="" />
          </div>
          <div className="name">{getIndex(0).name}</div>
          <div className="value">{getIndex(0).value}</div>
        </div>
        <div className="min-card">
          <div className="min-image">
            <img src={Img2} alt="" />
          </div>
          <div className="name">{getIndex(1).name}</div>
          <div className="value">{getIndex(1).value}</div>
        </div>
        <div className="min-card">
          <div className="min-image">
            <img src={Img3} alt="" />
          </div>
          <div className="name">{getIndex(2).name}</div>
          <div className="value">{getIndex(2).value * 100}%</div>
        </div>
      </div>
      <div className="max-card">
        <div className="max-img">
          <img src={Img4} alt="" />
        </div>
        <div className="text-content">
          <div className="left">
            <div className="title">{getIndex(3).name}</div>
            <div className="value">{parseCurrency(getIndex(3).value)}</div>
            <div className="title">{getIndex(5).name}</div>
            <div className="value">{parseCurrency(getIndex(5).value)}</div>
          </div>
          <div className="right">
            <div className="title">{getIndex(4).name}</div>
            <div className="value">{parseCurrency(getIndex(4).value)}</div>
            <div className="title">{getIndex(6).name}</div>
            <div className="value">{parseCurrency(getIndex(6).value)}</div>
          </div>
        </div>
      </div>

      <div className="subject_name" style={{ marginTop: "3.83rem" }}>
        售后
      </div>
      <div className="card-container">
        <div className="min-card">
          <div className="min-image">
            <img src={Img5} alt="" />
          </div>
          <div className="name">{getIndex(7).name}</div>
          <div className="value">{getIndex(7).value}</div>
        </div>
      </div>

      <div className="max-card" style={{ height: "13.18rem" }}>
        <div className="max-img">
          <img src={Img6} alt="" />
        </div>
        <div className="text-content">
          <div className="left">
            <div className="title">{getIndex(8).name}</div>
            <div className="value">{parseCurrency(getIndex(8).value)}</div>
          </div>
          <div className="right">
            <div className="title">{getIndex(9).name}</div>
            <div className="value">{getIndex(9).value * 100}%</div>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="subject_name" style={{ marginTop: "1.46rem" }}>
        集团费用状况
      </div>
      <div className="incomes-chart">
        <Income
          name="职能部门费用构成"
          year={year}
          month={month}
          subjectIds={[
            "301010",
            "301020",
            "301030",
            "301040",
            "301050",
            "302010",
          ]}
        />
        <Income
          name="业务费用构成"
          year={year}
          month={month}
          subjectIds={["706001", "706002", "706004", "3030"]}
        />
      </div>
      {/* {!showMessage && <div className="message-icon">
        <MessageFill onClick={()=>{
          setShowMessage(true)
        }}/>
      </div>} */}
      {<Message showMessage={showMessage} close={()=>setShowMessage(false)} />}
      {<Report showMessage={showReport} close={()=>setShowReport(false)} />}
    </div>
  );
}

export default Dashboard;
