import React, { useState, useEffect } from "react";
import http from "./http";
import { handleMonth, handelResp } from "./api";
import { Button,Calendar, Toast, Picker } from "antd-mobile";
import "./reportform.less";
import dayjs from 'dayjs'

const Items = [
  {
    title: "订单",
    items: [
      {
        label: "当日展厅新增订单",
        name: "daily1",
      },
      {
        label: "当日DCC新增订单",
        name: "daily2",
      },
      {
        label: "当日二网新增订单",
        name: "daily3",
      },
    ],
  },
  {
    title: "销量",
    items: [
      {
        label: "当日展厅交付",
        name: "daily4",
      },
      {
        label: "当日DCC交付",
        name: "daily5",
      },
      {
        label: "当日二网交付",
        name: "daily6",
      },
    ],
  },
  {
    title: "流量",
    items: [
      {
        label: "当日展厅客流",
        name: "daily9",
      },
      {
        label: "当日DCC线索",
        name: "daily10",
      },
    ],
  },
];

const Item2 = [
  {
    title: "销量",
    items: [
      {
        label: "当日批售车交付",
        name: "daily7",
      },
      {
        label: "当日9字头交付",
        name: "daily8",
      },
    ],
  },
];
function Reportform() {
  const [data, setData] = useState({});
  const [date, setDate] = useState(new Date())
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showCompPicker, setShowCompPicker] = useState(false)
  const [comps, setComps] = useState([])
  const [coId, setCoId] = useState(localStorage.getItem("coId"))
  const [co_type, setco_type] = useState(localStorage.getItem("co_type"))
  const [co_name, setco_name] = useState(localStorage.getItem("co_name"))
  const getCopms = async ()=>{
    const resp = await http.get('/base/selectUserCompany')
    handelResp(resp).then(data=>{
      if(data.userCompanys){
        setComps(data.userCompanys)
      }else{
        setComps([])
      }
    })
  }
  const getData = async () => {
    const now = date;
    const resp = await http.get("/tSalesDaily/select", {
      co_id: coId,
      year: now.getFullYear().toString(),
      month: handleMonth(now.getMonth() + 1),
      day: handleMonth(now.getDate().toString()),
      page_size: 1,
      page: 1,
    });
    handelResp(resp).then((data) => {
      if (data.tSalesDailys && data.tSalesDailys.length) {
        setData(data.tSalesDailys[0]);
      }else{
        setData({})
      }
    });
  };

  useEffect(()=>{
    getCopms()
  },[])

  useEffect(() => {
    getData();
  }, [date, coId]);
  const submit = async () => {
    console.log(data);
    const now = date;
    const param = {
      co_id: coId,
      year: now.getFullYear().toString(),
      month: handleMonth(now.getMonth() + 1),
      day: handleMonth(now.getDate().toString()),
      ...data,
    };
    const resp = await (data.id
      ? http.put("/tSalesDaily/update", param)
      : http.post("/tSalesDaily/create", param));
    // console.log(resp);
    handelResp(resp).then((data) => {
      // console.log(data);
      // message.success("提交成功");
      Toast.show({
        icon: 'success',
        content: "提交成功",
      })
      getData();
    }).catch(e=>{
      Toast.show({
        icon: 'fail',
        content: "提交失败",
      })
    });
  };

  return (
    <div className="content-dr1">
      <div className="header">
        <h1>{co_name}日报</h1>
      </div>
      <div className="head">
        <span>选择时间:</span>&nbsp;&nbsp;<Button size="small" onClick={()=>{
          setShowDatePicker(!showDatePicker)
        }}>{date.getFullYear()}-{handleMonth(date.getMonth()+1)}-{handleMonth(date.getDate())}</Button>
        {showDatePicker && <div className="date-container">
        <Calendar
          renderLabel={date => {
            if (dayjs(date).isSame(dayjs(), 'day')) return '今天'
            if (date.getDay() === 0 || date.getDay() === 6) {
              return '周末'
            }
          }}
          selectionMode='single'
          value={date}
          onChange={val => {
            if(!val){
              setShowDatePicker(false)
              return
            }
            setDate(val)
            setShowDatePicker(false)
          }}
        />
        </div>}&nbsp;&nbsp;
        <span>选择公司:</span>&nbsp;&nbsp;
        <Button size="small" onClick={()=>{
          setShowCompPicker(!showCompPicker)
        }} >{co_name}</Button>
        <Picker columns={[comps.map(val=>{
          return {
            label:val.co_name,
            value: val.co_id
          }
        })]} visible={showCompPicker} 
          value={[coId]}
          onClose={()=>setShowCompPicker(false)}
          onConfirm={v=>{
            // console.log(v)
            const e = v[0]
            const comp = comps.filter(val=>val.co_id === e)[0]
            setCoId(e)
            setco_name(comp.co_name)
            setco_type(comp.co_type)
          }}
        ></Picker>
      </div>
      {(co_type === "WS" ? Item2 : Items).map(
        (item, index) => {
          return (
            <div className="part" key={index}>
              <div className="title-container">
                <div className="line-left"></div>
                <div className="title">{item.title}</div>
                <div className="line-right"></div>
              </div>
              <div className="input-content">
                {item.items.map((val, i) => {
                  return (
                    <div className="input-item" key={i}>
                      <div className="label">{val.label}</div>
                      <div className="input">
                        <input
                          type="number"
                          value={data[val.name] ? data[val.name] : ''}
                          onChange={(e) => {
                            // console.log(e)
                            e = e.target.value;
                            e = e ? parseFloat(e) : 0;
                            data[val.name] = e;
                            setData({ ...data });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
      )}
      <div className="btn">
        <Button block size="large" color="primary" onClick={submit}>
          提交
        </Button>
      </div>
    </div>
  );
}

export default Reportform;
