/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { Form, Input, Button, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import http from "./http";
import { getCurrentBudgetInfo, handelResp } from "./api";
import "./login.less";

export default (props) => {
  
  const navigate = useNavigate();
  const [remb, setRemb] = useState(false);
  const onFinish = async (values) => {
    if (remb) {
      localStorage.setItem("user_name", values.user_name);
      localStorage.setItem("password", values.password);
    }else{
      localStorage.removeItem("user_name",);
      localStorage.removeItem("password",);
    }
    const resp = await http.post("/userLogin", values);

    // console.log(resp)

    if (resp.code === 200) {
      // localStorage.setItem('token', resp.data.token)
      Object.keys(resp.data).forEach((val) => {
        localStorage.setItem(val, resp.data[val]);
      });

      // console.log( resp.data)
      //   setStore({coId: resp.data.coId})
      //   console.log(props);
      
      const res = await http.get("/base/getUserInfo");
      handelResp(res).then(async () => {
        localStorage.setItem("co_type", res.data.mUserExt01s[0].co_type);
        localStorage.setItem("co_name", res.data.mUserExt01s[0].co_name);
        localStorage.setItem(
          "display_name",
          res.data.mUserExt01s[0].display_name
        );
        const su = res.data.mUserExt01s[0].co_type === "HQ" ? true : false;
        if(su){
          try{
            const data = await getCurrentBudgetInfo();
            localStorage.setItem("month", data.achievementBudgetFlow.month);
            localStorage.setItem("year", data.achievementBudgetFlow.year);
          }catch(e){
            console.log(e)
          }
          navigate("/dashboard");
        }else{
          navigate("/reportform");
        }
      });
      
    } else {
      //   message.error(resp.msg)
      Toast.show({
        icon: "fail",
        content: resp.msg,
      });
    }
  };
  const user_name = localStorage.getItem("user_name");
  const password = localStorage.getItem("password");
  return (
    <div className="loginWrap">
      <div className="loginHeader">
        <div className="logo">
          <img src="./favicon.ico" alt="" />
        </div>
        <h1>4S集团管理决策系统</h1>
      </div>
      <div className="loginForm">
        <Form
          layout="horizontal"
          onFinish={onFinish}
          footer={
            <div className="footer-container">
              <div className="tips">
                <div className="txt">* 账号密码不可为空</div>
                <div className="reb">
                  <input
                    name="remb"
                    onChange={(e) => {
                      // console.log(e.target.checked)
                      setRemb(e.target.checked);
                    }}
                    type="checkbox"
                    value=""
                  />
                  <span>记住账号密码</span>
                </div>
              </div>
              <Button size="large" block type="submit" color="primary">
                登陆
              </Button>
            </div>
          }
        >
          <Form.Item
            name="user_name"
            label=""
            initialValue={user_name ? user_name : ""}
            rules={[{ required: true, message: "用户名不能为空" }]}
            extra={<span className="dot">*</span>}
          >
            <Input placeholder="请输入用户名" clearable />
          </Form.Item>
          <Form.Item
            style={{ marginTop: "2rem" }}
            name="password"
            initialValue={password ? password : ""}
            label=""
            rules={[{ required: true, message: "密码不能为空" }]}
            extra={<span className="dot">*</span>}
          >
            <Input type="password" placeholder="请输入密码" clearable />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
