import React from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import MobileLogin from "./login";
import Dashboard from "./dashboard";
import ReportForm from "./reportform";
import "./App.less";

// window.onresize = ()=>{
//   window.location.reload()
// }

export default () => {
  return (
    <div className="mobile">
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<MobileLogin />}></Route>
          <Route exact path="/dashboard" element={<Dashboard />}></Route>
          <Route exact path="/reportform" element={<ReportForm />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

document.addEventListener('gesturestart', function (event) {
  event.preventDefault()
})

