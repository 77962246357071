import React, { useEffect, useState, useRef } from "react";
import {
  AudioOutlined,
  SendOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { MessageOutline } from "antd-mobile-icons";
import { DotLoading } from "antd-mobile";
import "./message.less";
import CryptoJS from "crypto-js";
//必须引入的核心，换成require也是一样的。注意：recorder-core会自动往window下挂载名称为Recorder对象，全局可调用window.Recorder，也许可自行调整相关源码清除全局污染
import Recorder from "recorder-core";
import http from "./http";

//引入相应格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine"; //如果此格式有额外的编码引擎（*-engine.js）的话，必须要加上
import { handelResp } from "./api";

const AppId = "1305023162";
const SecretID = "AKIDObXBXbYNvDi02oIYaMsKdhhpoY0MXT9Z";
const SecretKey = "FOL5e088bqqZ1EDxRfeNoBN8j6FApEO0";

function sha256(message, secret, encoding) {
  const hmac = CryptoJS.HmacSHA256(message, secret);
  return hmac.toString();
}

function getHash(message) {
  return CryptoJS.SHA256(message).toString();
}

function getDate(timestamp) {
  const date = new Date(timestamp * 1000);
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  return `${year}-${month}-${day}`;
}
function tc3(data) {
  const params = {
    UsrAudioKey: "test",
    SubServiceType: 2,
    ProjectId: 0,
    EngSerViceType: "16k_zh",
    VoiceFormat: "mp3",
    Data: data,
    SourceType: 1,
  };

  const endpoint = "asr.tencentcloudapi.com";
  const service = "asr";
  const region = "ap-shanghai";
  const action = "SentenceRecognition";
  const version = "2023-03-20";
  const timestamp = new Date().getTime();
  // const timestamp = 1551113065
  //时间处理, 获取世界时间日期
  const date = getDate(timestamp);

  // ************* 步骤 1：拼接规范请求串 *************
  //  const payload = "{\"Limit\": 1, \"Filters\": [{\"Values\": [\"\\u672a\\u547d\\u540d\"], \"Name\": \"instance-name\"}]}"

  const hashedRequestPayload = getHash(JSON.stringify(params));
  const httpRequestMethod = "POST";
  const canonicalUri = "/";
  const canonicalQueryString = "";
  const canonicalHeaders =
    "content-type:application/json; charset=utf-8\n" +
    "host:" +
    endpoint +
    "\n" +
    "x-tc-action:" +
    action.toLowerCase() +
    "\n";
  const signedHeaders = "content-type;host;x-tc-action";

  const canonicalRequest =
    httpRequestMethod +
    "\n" +
    canonicalUri +
    "\n" +
    canonicalQueryString +
    "\n" +
    canonicalHeaders +
    "\n" +
    signedHeaders +
    "\n" +
    hashedRequestPayload;
  console.log(canonicalRequest);

  // ************* 步骤 2：拼接待签名字符串 *************
  const algorithm = "TC3-HMAC-SHA256";
  const hashedCanonicalRequest = getHash(canonicalRequest);
  const credentialScope = date + "/" + service + "/" + "tc3_request";
  const stringToSign =
    algorithm +
    "\n" +
    timestamp +
    "\n" +
    credentialScope +
    "\n" +
    hashedCanonicalRequest;
  console.log(stringToSign);

  // ************* 步骤 3：计算签名 *************
  const kDate = sha256(date, "TC3" + SecretKey);
  const kService = sha256(service, kDate);
  const kSigning = sha256("tc3_request", kService);
  const signature = sha256(stringToSign, kSigning, "hex");
  console.log(signature);

  const authorization =
    algorithm +
    " " +
    "Credential=" +
    SecretID +
    "/" +
    credentialScope +
    ", " +
    "SignedHeaders=" +
    signedHeaders +
    ", " +
    "Signature=" +
    signature;
  console.log(authorization);

  // const curlcmd = 'curl -X POST ' + "https://" + endpoint
  //                        + ' -H "Authorization: ' + authorization + '"'
  //                        + ' -H "Content-Type: application/json; charset=utf-8"'
  //                        + ' -H "Host: ' + endpoint + '"'
  //                        + ' -H "X-TC-Action: ' + action + '"'
  //                        + ' -H "X-TC-Timestamp: ' + timestamp.toString() + '"'
  //                        + ' -H "X-TC-Version: ' + version + '"'
  //                        + ' -H "X-TC-Region: ' + region + '"'
  //                        + " -d '" + payload + "'"
  // console.log(curlcmd)
  console.log(params);
  // return axios({
  //   method: 'post',
  //   baseURL: '//' + window.location.host,
  //   url: '/asr',
  //   data: params,
  //   headers: {
  //     Authorization: authorization,
  //     'Content-Type': 'application/json; charset=utf-8',
  //     // 'Host': endpoint,
  //     'X-TC-Action': action,
  //     'X-TC-Timestamp': timestamp.toString(),
  //     'X-TC-Version': version,
  //     'X-TC-Region': region
  //   }
  // })
}

window.tc3 = tc3;

// const rec = Recorder({ //本配置参数请参考下面的文档，有详细介绍
//     type:"mp3",sampleRate:16000,bitRate:16 //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
// });

// // rec.open()

// window.rec = rec

// window.rc_stop = ()=>{
//   rec.stop((blob, d)=>{
//     console.log(d, blob)
//     const fileReader = new FileReader()
//     fileReader.onload = e =>{
//       tc3(e.target.result)
//     }
//     fileReader.readAsDataURL(blob)
//   })
// }
let rec = null;
function handleMonth(val) {
  const mp = {
    "1Q": "一季度",
    "2Q": "二季度",
    "3Q": "三季度",
    "4Q": "四季度",
    "1H": "上半年",
    "2H": "下半年",
    Y: "全年",
  };

  if (val.indexOf("Q") > -1 || val.indexOf("H") > -1 || val.indexOf("Y") > -1) {
    return mp[val];
  }
  return val + "月";
}

function Message(props) {
  const [onTouch, setTouch] = useState(false);
  const [value, setValue] = useState("");
  const [msg, setMsg] = useState([]);
  const ref = useRef();

  const send = async () => {
    const txt = value;
    setValue("");
    msg.push({
      type: "u",
      msg: txt,
    });
    msg.push({
      type: "l",
    });
    setMsg([...msg]);
    const resp = await http.put("/base/getIndicatorFromChat", {
      co_id: localStorage.getItem("coId"),
      req_str: txt,
    });

    handelResp(resp).then((data) => {
      // console.log(data);
      msg.pop();
      const flag = Object.keys(data)[0].indexOf("01") > -1;
      if (flag) {
        // flag ? data.indiResult01s[0] :
        msg.push({
          type: "s01",
          data: data.indiResult01s,
        });
      } else {
        msg.push({
          type: "s05",
          data: data.indiResult05,
        });
      }
      setMsg([...msg]);
    });
  };

  useEffect(() => {
    if (ref.current) {
      // console.log(ref)
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [msg]);

  const Tips = (
    <div>
      <p>未查到有效数据</p>
      <p>您可以按以下方式提出数据查询要求</p>
      <p>公司名+年月/季度 + 指标名称</p>
      <p>如：圆众去年3月销售利润和净利润</p>
    </div>
  );

  const handleType = (item) => {
    switch (item.type) {
      case "l":
        return (
          <div className="left">
            <DotLoading color="currentColor" />
          </div>
        );
      case "u":
        return <div className="right">{item.msg}</div>;
      case "s01":
        return item.data.map((val, index) => {
          const subjectReportData01s = val.subjectReportData01s;
          const indiReq = val.indiReq;
          return (
            <div key={index}>
              <div className="label">
                {indiReq.co_id}&nbsp;{indiReq.year}年
                {handleMonth(indiReq.month)}&nbsp;{indiReq.business_cd}&nbsp;
                {indiReq.ea_type}
              </div>
              <div className="left">
                {!subjectReportData01s
                  ? Tips
                  : subjectReportData01s.map((val, key) => {
                      return (
                        <div className="s01" key={key}>
                          <span>{val.subject_name}</span>
                          <span className="value">
                            {val.subject_value
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                          </span>
                        </div>
                      );
                    })}
              </div>
            </div>
          );
        });
      case "s05":
        const subjectReportData05s = item.data.subjectReportData05s;
        const indiReq = item.data.indiReq;
        return (
          <div>
            <div className="label">
              {indiReq.co_id}&nbsp;{indiReq.year}年{handleMonth(indiReq.month)}
              &nbsp;{indiReq.business_cd}&nbsp;{indiReq.ea_type}
            </div>
            <div className="left">
              {!subjectReportData05s
                ? Tips
                : subjectReportData05s.map((val, index) => {
                    return (
                      <div className="s05" key={index}>
                        <div className="title">
                          <span>{val.subject_name}</span>
                          <span className="value">
                            {val["achievement_value_m" + indiReq.month]
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                          </span>
                        </div>
                        <div className="es">
                          <span></span>
                          <span>
                            预算：
                            {val["estimate_value_m" + indiReq.month]
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                          </span>
                        </div>
                        <div className="progress">
                          <span>
                            同比：
                            { val["past1_value_m" + indiReq.month] ? (
                              ((val["achievement_value_m" + indiReq.month] -
                                val["past1_value_m" + indiReq.month]) /
                                Math.abs(
                                  val["past1_value_m" + indiReq.month]
                                )) *
                              100
                            )
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,") : 0}
                            %
                            {val["achievement_value_m" + indiReq.month] -
                              val["past1_value_m" + indiReq.month] >
                            0 ? (
                              <span className="arrow-up">
                                {" "}
                                <ArrowUpOutlined />
                              </span>
                            ) : (
                              <span className="arrow-down">
                                {" "}
                                <ArrowDownOutlined />
                              </span>
                            )}
                          </span>
                          <span>
                            完成率：
                            {(
                              (val["achievement_value_m" + indiReq.month] /
                                val["estimate_value_m" + indiReq.month]) *
                              100
                            )
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                            %
                          </span>
                        </div>
                        {index !== subjectReportData05s.length - 1 && (
                          <div className="item-line"></div>
                        )}
                      </div>
                    );
                  })}
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div
      className="message-container"
      style={{ display: props.showMessage ? "" : "none" }}
    >
      {/* <div
        className="down"
        onClick={() => {
          props.close && props.close();
        }}
      >
        <DownOutline />
      </div> */}
      <div className="msg-container" ref={ref}>
        {msg.map((item, index) => {
          return (
            <div
              className={item.type === "u" ? "msg-item r" : "msg-item l"}
              key={index}
            >
              {item.type !== "u" && (
                <div className="head-icon">
                  <MessageOutline />
                </div>
              )}
              <div>{handleType(item)}</div>
            </div>
          );
        })}
      </div>
      {/* <div
        className={onTouch ? "speak speaking" : "speak"}
        onTouchStart={() => {
          setTouch(true);
        }}
        onTouchEnd={() => {
          setTouch(false);
        }}
      >
        <AudioOutlined />
      </div> */}
      <div className="input-container">
        <div className="input-container-wrap">
          <div
            className="voice icon"
            onTouchStart={() => {
              rec = Recorder({
                //本配置参数请参考下面的文档，有详细介绍
                type: "mp3",
                sampleRate: 16000,
                bitRate: 16, //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
              });
              rec.open(
                function () {
                  //打开麦克风授权获得相关资源
                  //dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
                  //rec.start() 此处可以立即开始录音，但不建议这样编写，因为open是一个延迟漫长的操作，通过两次用户操作来分别调用open和start是推荐的最佳流程
                  rec.start();
                },
                function (msg, isUserNotAllow) {
                  //用户拒绝未授权或不支持
                  //dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
                  console.log(
                    (isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg
                  );
                }
              );
            }}
            onTouchEnd={() => {
              rec.stop(
                function (blob, duration) {
                  console.log(
                    blob,
                    window.URL.createObjectURL(blob),
                    "时长:" + duration + "ms"
                  );
                  rec.close(); //释放录音资源，当然可以不释放，后面可以连续调用start；但不释放时系统或浏览器会一直提示在录音，最佳操作是录完就close掉
                  var reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onload = function (e) {
                    console.log(e.target.result);
                    const b64 = e.target.result;
                    http
                      .post("/base/getSentence", {
                        data: b64.split(",")[1],
                      })
                      .then((resp) => {
                        handelResp(resp).then((data) => {
                          // console.log(data)
                          setValue(value + data.sentence);
                        });
                      });
                  };
                },
                function (msg) {
                  console.log("录音失败:" + msg);
                  rec.close(); //可以通过stop方法的第3个参数来自动调用close
                  rec = null;
                }
              );
            }}
          >
            <AudioOutlined />
          </div>
          <div
            className="txt"
            contentEditable="true"
            placeholder="请输入您要查询的数据……"
            dangerouslySetInnerHTML={{ __html: value }}
            onCopy={(e) => {
              console.log(e);
            }}
            onInput={(e) => {
              // setValue(e.target.innerText)
              // e.target.innerHTML = e.target.innerText
              // console.log(e.target.innerText)
              // console.log(e.target.innerHTML)
            }}
            onBlur={(e) => {
              // console.log(e)
              if (e.target.innerText) {
                setValue(e.target.innerText);
              }
            }}
          ></div>
          <div className="send icon" onClick={send}>
            {/* <SendOutlined /> */}
            发送
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
